import { createApp, h } from 'vue'
import { createVuetify } from 'vuetify'

const DriftLightTheme = {
  dark: false,
  colors: {
    primary: '#30227e',
    secondary: '#4a60b0',
    error: '#c93e4c',
    info: '#73b4da',
    success: '#4caf50',
    warning: '#ff9710'
  }
}

export default function vueRenderer(id, component, hasOptionDataId=undefined) {
  document.addEventListener('turbolinks:load', async () => {
    const target = document.getElementById(id)
    if (target) {
      const props = hasOptionDataId ? { ...target.dataset, ...document.getElementById(hasOptionDataId).dataset } : target.dataset
      const app = createApp({
        render() {
          return h(component, { ...props })
        }
      })
      const vuetify = createVuetify({
        theme: {
          defaultTheme: 'DriftLightTheme',
          themes: {
            DriftLightTheme,
          },
        },
        icons: {
          defaultSet: 'mdi'
        }
      })
      app.use(vuetify)
      app.mount(target)
    }
  })

  document.addEventListener("turbolinks:before-cache", () => {
    const target = document.getElementById(id)
    if (target) {
      target.__vue_app__?.unmount()
    }
  })
}
